import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Ruby を使って HPKI カードのデータを読み取る",
  "date": "2018-07-12T06:23:31.000Z",
  "slug": "entry/2018/07/12/152331",
  "tags": ["medley"],
  "hero": "./2018_07_12.png",
  "heroAlt": "HPKI"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`こんにちは、開発本部の宮内です。今回、HPKI カードについて調査を行いましたので、それについて書きます。`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.jahis.jp/standard/detail/id=609"
      }}>{`JAHIS HPKI 対応 IC カードガイドライン Ver.3.0`}</a>{`を参考にして、HPKI テストカードから実際に公開鍵証明書を取得しました。`}</p>
    <p>{`今後も HPKI について調査を続行していきたいと思います。`}</p>
    <h1>{`HPKI とは？`}</h1>
    <p><a parentName="p" {...{
        "href": "https://www.medis.or.jp/8_hpki/"
      }}>{`HPKI`}</a>{`とは厚生労働省が認める医療福祉関係資格（医師・薬剤師・看護師など 26 種類の保健医療福祉分野の国家資格と、院長・管理薬剤師など 5 種類の管理者資格）を認証することができる PKI です。`}</p>
    <p>{`配布された HPKI カードには、ルート CA、中間 CA、証明書が格納されています。`}</p>
    <p>{`このカードは、電子署名などに使用することができ、今後普及していけば、医療文書（処方箋や病院への紹介状など）を印刷、押印、送付するなどの非効率な業務をすることなく、すべてデジタル化することができるようになります。`}</p>
    <p>{`また、電子認証用の証明書も含まれているため、認証・認可処理にも使用することができます。`}</p>
    <p>{`今回、HPKI テストカードを用いて調査を行いました。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20180712/20180712151907.jpg",
      "alt": "20180712151907.jpg"
    }}></img>
    <h1>{`調査環境`}</h1>
    <ul>
      <li parentName="ul">{`macOS v10.13.5`}</li>
      <li parentName="ul">{`ACR39-NTTCom`}</li>
      <li parentName="ul">{`Ruby v2.5.1`}</li>
      <li parentName="ul">{`smartcard v0.5.6`}</li>
      <li parentName="ul">{`HPKI テスト用カード`}</li>
    </ul>
    <h1>{`PC/SC`}</h1>
    <p>{`HPKI カードのような IC カードとやり取りを行うには、`}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/PC/SC"
      }}>{`PC/SC`}</a>{`という API 仕様を使う必要があります。`}</p>
    <p>{`PC/SC はもともと Windows 環境のみで利用可能でしたが、pcsc-lite という OSS 実装があり、現在では様々な UNIX like OS でも利用できます。`}</p>
    <p>{`macOS の場合、`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/System/Library/Frameworks/PCSC.framework/PCSC`}</code>{`にライブラリが用意されており、特に準備する必要なく利用可能です。(2018 年 07 月現在)`}</p>
    <p>{`ただし、IC カードリーダーのドライバーをインストールする必要があります。`}</p>
    <p>{`今回利用した`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`ACR39-NTTCom`}</code>{`は`}<a parentName="p" {...{
        "href": "https://www.ntt.com/business/services/application/authentication/jpki/download6.html"
      }}>{`ダウンロードページ`}</a>{`に macOS v10.13 に対応したドライバーが配布されていなかったため、IC カードリーダーのチップメーカーである ACS 社の`}<a parentName="p" {...{
        "href": "https://www.acs.com.hk/en/driver/302/acr39u-smart-card-reader/"
      }}>{`ダウンロードページ`}</a>{`からドライバーを入手しました。`}</p>
    <h1>{`smartcard`}</h1>
    <p>{`検証する際に使用した gem は`}<a parentName="p" {...{
        "href": "https://rubygems.org/gems/smartcard"
      }}>{`smartcard`}</a>{`です。
普通の rubygem と同じく`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`gem install`}</code>{`して利用します。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "shell"
    }}><pre parentName="div" {...{
        "className": "language-shell"
      }}><code parentName="pre" {...{
          "className": "language-shell"
        }}>{`gem `}<span parentName="code" {...{
            "className": "token function"
          }}>{`install`}</span>{` smartcard`}</code></pre></div>
    <p>{`IC カードリーダーを PC に接続し、`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "shell"
    }}><pre parentName="div" {...{
        "className": "language-shell"
      }}><code parentName="pre" {...{
          "className": "language-shell"
        }}>{`ruby -rsmartcard -e `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'pp Smartcard::PCSC::Context.new.readers'`}</span></code></pre></div>
    <p>{`を実行し、IC カードリーダー名が表示されれば接続成功です。`}</p>
    <h1>{`アプリケーション識別子の取得`}</h1>
    <p>{`実際に HPKI テストカードから情報を取得していきます。`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.jahis.jp/standard/detail/id=609"
      }}>{`ガイドライン`}</a>{`の「附属書 A(参考)PKI カードアプリケーション利用のシーケンス」にある「A.2.2 JIS X 6320-15 に従った PKI カードアプリケーションの検索と利用」を実装していきます。`}</p>
    <blockquote>
      <img parentName="blockquote" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20180712/20180712152004.png",
        "alt": "20180712152004.png"
      }}></img>
      <p parentName="blockquote">{`引用 `}<a parentName="p" {...{
          "href": "https://www.jahis.jp/standard/detail/id=609"
        }}>{`ガイドライン`}</a></p>
    </blockquote>
    <p><em parentName="p">{`prog01.rb`}</em></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`# prog01.rb`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`require`}</span>{` `}<span parentName="code" {...{
            "className": "token string-literal"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`"smartcard"`}</span></span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`def`}</span>{` `}<span parentName="code" {...{
            "className": "token method-definition"
          }}><span parentName="span" {...{
              "className": "token function"
            }}>{`puts_response`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`response`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  puts `}<span parentName="code" {...{
            "className": "token string-literal"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`"status = %04X"`}</span></span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`%`}</span>{` response`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token symbol"
          }}>{`:status`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
  puts `}<span parentName="code" {...{
            "className": "token string-literal"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`"data = %s"`}</span></span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`%`}</span>{` response`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token symbol"
          }}>{`:data`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`map `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{`i`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` `}<span parentName="code" {...{
            "className": "token string-literal"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`"%02X"`}</span></span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`%`}</span>{` i `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`join`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string-literal"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`" "`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span>{`

context `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` Smartcard`}<span parentName="code" {...{
            "className": "token double-colon punctuation"
          }}>{`::`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`PCSC`}</span><span parentName="code" {...{
            "className": "token double-colon punctuation"
          }}>{`::`}</span><span parentName="code" {...{
            "className": "token class-name"
          }}>{`Context`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`begin`}</span>{`
  card `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` context`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`card context`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`readers`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`first

  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# SELECT コマンドで\`E8 28 BD 08 0F\`をパーシャル指定した DF を指定`}</span>{`
  apdu `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0x00`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
          `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0xA4`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
          `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x04`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
          `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x00`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
          `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x05`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
          `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0xE8`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x28`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0xBD`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x08`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x0F`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
          `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x00`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
  response `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` card`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`transmit apdu`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`pack`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string-literal"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`"C*"`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  response `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` Smartcard`}<span parentName="code" {...{
            "className": "token double-colon punctuation"
          }}>{`::`}</span>{`Iso`}<span parentName="code" {...{
            "className": "token double-colon punctuation"
          }}>{`::`}</span>{`IsoCardMixin`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`deserialize_response response`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`unpack`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string-literal"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`"C*"`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  puts_response response

  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`while`}</span>{` response`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token symbol"
          }}>{`:status`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`==`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x9000`}</span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# SELECT コマンドで次の DF を探す`}</span>{`
    apdu `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0x00`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0xA4`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x04`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x02`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x05`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0xE8`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x28`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0xBD`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x08`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x0F`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x00`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
    response `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` card`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`transmit apdu`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`pack`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string-literal"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`"C*"`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    response `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` Smartcard`}<span parentName="code" {...{
            "className": "token double-colon punctuation"
          }}>{`::`}</span>{`Iso`}<span parentName="code" {...{
            "className": "token double-colon punctuation"
          }}>{`::`}</span>{`IsoCardMixin`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`deserialize_response response`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`unpack`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string-literal"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`"C*"`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    puts_response response
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`ensure`}</span>{`
  context`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`release
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span></code></pre></div>
    <p>{`上記のプログラムを実行すると、次のような出力が得られます。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "shell"
    }}><pre parentName="div" {...{
        "className": "language-shell"
      }}><code parentName="pre" {...{
          "className": "language-shell"
        }}>{`status `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`9000`}</span>{`
data `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` 6F `}<span parentName="code" {...{
            "className": "token number"
          }}>{`12`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`84`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`10`}</span>{` E8 `}<span parentName="code" {...{
            "className": "token number"
          }}>{`28`}</span>{` BD 08 0F A0 00 00 03 `}<span parentName="code" {...{
            "className": "token number"
          }}>{`91`}</span>{` 00 00 00 00 00 01
status `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`9000`}</span>{`
data `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` 6F `}<span parentName="code" {...{
            "className": "token number"
          }}>{`12`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`84`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`10`}</span>{` E8 `}<span parentName="code" {...{
            "className": "token number"
          }}>{`28`}</span>{` BD 08 0F A0 00 00 03 `}<span parentName="code" {...{
            "className": "token number"
          }}>{`91`}</span>{` 00 00 00 00 00 02
status `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` 6A82
data `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span></code></pre></div>
    <p>{`SELECT コマンドを発行すると`}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/X.690#BER_encoding"
      }}>{`BER-TLV`}</a>{`で符号化された FCI(ファイル制御情報)が取得できます。`}</p>
    <p>{`１つ目のデータから見ていきます。`}</p>
    <p>{`１バイト目は`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`6F`}</code>{`なので、このデータはファイル制御パラメタ及びファイル管理データの集合を表します。`}</p>
    <blockquote>
      <img parentName="blockquote" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20180712/20180712152144.png",
        "alt": "20180712152144.png"
      }}></img>
      <p parentName="blockquote">{`引用 JIS X 6320-4 表 8-ファイル制御情報用の産業感共通利用テンプレート`}</p>
    </blockquote>
    <p>{`２バイト目は`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`12`}</code>{`なので、後続するデータの長さが 18 バイトあることを表します。`}</p>
    <p>{`３バイト目は`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`84`}</code>{`なので、データ要素が DF 名であることを表します。`}</p>
    <blockquote>
      <img parentName="blockquote" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20180712/20180712152208.png",
        "alt": "20180712152208.png"
      }}></img>
      <p parentName="blockquote">{`引用 JIS X 6320-4 表 10-ファイル制御パラメタデータオブジェクト`}</p>
    </blockquote>
    <p>{`４バイト目は`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`10`}</code>{`なので、後続するデータの長さが 16 バイトあることを表します。
５バイト目以降は、DF 名(= アプリケーション識別子)です。`}</p>
    <p>{`２つ目のデータもデータ構造は同じなため省略します。`}</p>
    <p>{`これで HPKI テストカードには、`}</p>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`E8 28 BD 08 0F A0 00 00 03 91 00 00 00 00 00 01`}</code></li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`E8 28 BD 08 0F A0 00 00 03 91 00 00 00 00 00 02`}</code></li>
    </ul>
    <p>{`という２つのアプリケーション識別子が含まれていることが分かります。`}</p>
    <h1>{`公開鍵証明書を取得する`}</h1>
    <p>{`前段にて HPKI テストカードに含まれているアプリケーション識別子が分かりましたので、次は公開鍵証明書を取得していきます。`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.jahis.jp/standard/detail/id=609"
      }}>{`ガイドライン`}</a>{`の「A.3.2 証明書の読み出し」にあるコマンドの通りに APDU を発行しても、正しいデータは返ってきません。
これは、HPKI テストカードの EF 識別子が、ガイドラインに記載されている EF 識別子とは異なるためです。`}</p>
    <p>{`HPKI カードは JIS X 6320 に準拠しているため、各種暗号情報オブジェクトへのパス情報を含んだ EF.OD が存在しています。
この EF.OD を使い公開鍵証明書へのパスを取得してから、公開鍵証明書を取得していきます。`}</p>
    <blockquote>
      <img parentName="blockquote" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20180712/20180712152245.png",
        "alt": "20180712152245.png"
      }}></img>
      <p parentName="blockquote">{`引用 `}<a parentName="p" {...{
          "href": "https://www.jahis.jp/standard/detail/id=609"
        }}>{`ガイドライン`}</a></p>
    </blockquote>
    <h2>{`EF.OD を読み込む`}</h2>
    <p><em parentName="p">{`prog02.rb`}</em></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`# prog02.rb`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`require`}</span>{` `}<span parentName="code" {...{
            "className": "token string-literal"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`"smartcard"`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`require`}</span>{` `}<span parentName="code" {...{
            "className": "token string-literal"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`"openssl"`}</span></span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`def`}</span>{` `}<span parentName="code" {...{
            "className": "token method-definition"
          }}><span parentName="span" {...{
              "className": "token function"
            }}>{`puts_response`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`response`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  puts `}<span parentName="code" {...{
            "className": "token string-literal"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`"status = %04X"`}</span></span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`%`}</span>{` response`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token symbol"
          }}>{`:status`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
  puts `}<span parentName="code" {...{
            "className": "token string-literal"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`"data = %s"`}</span></span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`%`}</span>{` response`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token symbol"
          }}>{`:data`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`map `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{`i`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` `}<span parentName="code" {...{
            "className": "token string-literal"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`"%02X"`}</span></span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`%`}</span>{` i `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`join`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string-literal"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`" "`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`def`}</span>{` `}<span parentName="code" {...{
            "className": "token method-definition"
          }}><span parentName="span" {...{
              "className": "token function"
            }}>{`decode_asn1`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`response`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  data `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` response`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token symbol"
          }}>{`:data`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`reverse_each`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`drop_while `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{`i`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` i `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`==`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0xFF`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`reverse
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` data`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`empty`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`?`}</span>{`
  OpenSSL`}<span parentName="code" {...{
            "className": "token double-colon punctuation"
          }}>{`::`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`ASN1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`decode_all data`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`pack`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string-literal"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`"C*"`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span>{`

context `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` Smartcard`}<span parentName="code" {...{
            "className": "token double-colon punctuation"
          }}>{`::`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`PCSC`}</span><span parentName="code" {...{
            "className": "token double-colon punctuation"
          }}>{`::`}</span><span parentName="code" {...{
            "className": "token class-name"
          }}>{`Context`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`begin`}</span>{`
  card `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` context`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`card context`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`readers`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`first

  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0xE8`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x28`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0xBD`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x08`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x0F`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0xA0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x00`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x00`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x03`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x91`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x00`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x00`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x00`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x00`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x00`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x01`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0xE8`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x28`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0xBD`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x08`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x0F`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0xA0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x00`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x00`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x03`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x91`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x00`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x00`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x00`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x00`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x00`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x02`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`each`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`do`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{`aid`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# SELECT コマンドでアプリケーションを選択する`}</span>{`
    apdu `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0x00`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0xA4`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x04`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x00`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x10`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`*`}</span>{`aid`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x00`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
    card`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`transmit apdu`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`pack`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string-literal"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`"C*"`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# EF.OD の読み出し`}</span>{`
    apdu `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0x00`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0xB0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x91`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x00`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x00`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
    response `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` card`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`transmit apdu`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`pack`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string-literal"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`"C*"`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    response `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` Smartcard`}<span parentName="code" {...{
            "className": "token double-colon punctuation"
          }}>{`::`}</span>{`Iso`}<span parentName="code" {...{
            "className": "token double-colon punctuation"
          }}>{`::`}</span>{`IsoCardMixin`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`deserialize_response response`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`unpack`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string-literal"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`"C*"`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    pp decode_asn1 response
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`ensure`}</span>{`
  context`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`release
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span></code></pre></div>
    <p>{`EF.OD を読み込むと DER 符号化されたデータが返ってきます。
これを `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`OpenSSL::ANS1`}</code>{` モジュールで復号化すると、次に取得するべき EF 識別子が分かります。`}</p>
    <p>{`EF.OD の ASN.1 定義は以下のようになっているため、タグが 4 であるデータを読み込めば良さそうです。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "asm6502"
    }}><pre parentName="div" {...{
        "className": "language-asm6502"
      }}><code parentName="pre" {...{
          "className": "language-asm6502"
        }}>{`CIOChoice `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`= CHOICE {
  privateKeys          [`}<span parentName="code" {...{
            "className": "token decimal-number number"
          }}>{`0`}</span>{`] PrivateKeys`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  publicKeys           [`}<span parentName="code" {...{
            "className": "token decimal-number number"
          }}>{`1`}</span>{`] PublicKeys`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  trustedPublicKeys    [`}<span parentName="code" {...{
            "className": "token decimal-number number"
          }}>{`2`}</span>{`] PublicKeys`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  secretKeys           [`}<span parentName="code" {...{
            "className": "token decimal-number number"
          }}>{`3`}</span>{`] SecretKeys`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  certificates         [`}<span parentName="code" {...{
            "className": "token decimal-number number"
          }}>{`4`}</span>{`] Certificates`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  trustedCertificates  [`}<span parentName="code" {...{
            "className": "token decimal-number number"
          }}>{`5`}</span>{`] Certificates`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  usefulCertificates   [`}<span parentName="code" {...{
            "className": "token decimal-number number"
          }}>{`6`}</span>{`] Certificates`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  dataContainerObjects [`}<span parentName="code" {...{
            "className": "token decimal-number number"
          }}>{`7`}</span>{`] DataContainerObjects`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  authObjects          [`}<span parentName="code" {...{
            "className": "token decimal-number number"
          }}>{`8`}</span>{`] AuthObjects`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
}`}</code></pre></div>
    <p>{`prog02.rb を実行して実際に得られたデータ`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "shell"
    }}><pre parentName="div" {...{
        "className": "language-shell"
      }}><code parentName="pre" {...{
          "className": "language-shell"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`
 `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# 中略`}</span>{`
 `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`#<OpenSSL::ASN1::ASN1Data:0x00007f8b8e0ef7b0`}</span>{`
  @indefinite_length`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`false,
  @tag`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`4`}</span>{`,
  @tag_class`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`:CONTEXT_SPECIFIC,
  @value`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`
   `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token comment"
          }}>{`#<OpenSSL::ASN1::Sequence:0x00007f8b8e0ef7d8`}</span>{`
     @indefinite_length`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`false,
     @tag`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`16`}</span>{`,
     @tag_class`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`:UNIVERSAL,
     @tagging`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`nil,
     @value`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token comment"
          }}>{`#<OpenSSL::ASN1::OctetString:0x00007f8b8e0ef800`}</span>{`
        @indefinite_length`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`false,
        @tag`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`4`}</span>{`,
        @tag_class`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`:UNIVERSAL,
        @tagging`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`nil,
        @value`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"`}<span parentName="span" {...{
              "className": "token entity",
              "title": "\\x00"
            }}>{`\\x00`}</span><span parentName="span" {...{
              "className": "token entity",
              "title": "\\x04"
            }}>{`\\x04`}</span>{`"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
 `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# 中略`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`
 `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# 中略`}</span>{`
 `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`#<OpenSSL::ASN1::ASN1Data:0x00007f8b8d118df0`}</span>{`
  @indefinite_length`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`false,
  @tag`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`4`}</span>{`,
  @tag_class`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`:CONTEXT_SPECIFIC,
  @value`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`
   `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token comment"
          }}>{`#<OpenSSL::ASN1::Sequence:0x00007f8b8d118e18`}</span>{`
     @indefinite_length`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`false,
     @tag`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`16`}</span>{`,
     @tag_class`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`:UNIVERSAL,
     @tagging`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`nil,
     @value`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token comment"
          }}>{`#<OpenSSL::ASN1::OctetString:0x00007f8b8d118e40`}</span>{`
        @indefinite_length`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`false,
        @tag`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`4`}</span>{`,
        @tag_class`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`:UNIVERSAL,
        @tagging`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`nil,
        @value`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"`}<span parentName="span" {...{
              "className": "token entity",
              "title": "\\x00"
            }}>{`\\x00`}</span><span parentName="span" {...{
              "className": "token entity",
              "title": "\\x04"
            }}>{`\\x04`}</span>{`"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`,
 `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# 中略`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span></code></pre></div>
    <p>{`どちらのアプリケーションも`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`00 04`}</code>{`が EF.CD(証明書オブジェクト情報)の EF 識別子だということが分かります。`}</p>
    <h2>{`EF.CD を読み込む`}</h2>
    <p><em parentName="p">{`prog03.rb`}</em></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`# prog03.rb`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`require`}</span>{` `}<span parentName="code" {...{
            "className": "token string-literal"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`"smartcard"`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`require`}</span>{` `}<span parentName="code" {...{
            "className": "token string-literal"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`"openssl"`}</span></span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`def`}</span>{` `}<span parentName="code" {...{
            "className": "token method-definition"
          }}><span parentName="span" {...{
              "className": "token function"
            }}>{`puts_response`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`response`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  puts `}<span parentName="code" {...{
            "className": "token string-literal"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`"status = %04X"`}</span></span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`%`}</span>{` response`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token symbol"
          }}>{`:status`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
  puts `}<span parentName="code" {...{
            "className": "token string-literal"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`"data = %s"`}</span></span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`%`}</span>{` response`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token symbol"
          }}>{`:data`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`map `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{`i`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` `}<span parentName="code" {...{
            "className": "token string-literal"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`"%02X"`}</span></span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`%`}</span>{` i `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`join`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string-literal"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`" "`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`def`}</span>{` `}<span parentName="code" {...{
            "className": "token method-definition"
          }}><span parentName="span" {...{
              "className": "token function"
            }}>{`decode_asn1`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`response`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  data `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` response`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token symbol"
          }}>{`:data`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`reverse_each`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`drop_while `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{`i`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` i `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`==`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0xFF`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`reverse
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` data`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`empty`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`?`}</span>{`
  OpenSSL`}<span parentName="code" {...{
            "className": "token double-colon punctuation"
          }}>{`::`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`ASN1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`decode_all data`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`pack`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string-literal"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`"C*"`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span>{`

context `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` Smartcard`}<span parentName="code" {...{
            "className": "token double-colon punctuation"
          }}>{`::`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`PCSC`}</span><span parentName="code" {...{
            "className": "token double-colon punctuation"
          }}>{`::`}</span><span parentName="code" {...{
            "className": "token class-name"
          }}>{`Context`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`begin`}</span>{`
  card `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` context`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`card context`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`readers`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`first

  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0xE8`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x28`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0xBD`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x08`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x0F`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0xA0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x00`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x00`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x03`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x91`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x00`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x00`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x00`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x00`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x00`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x01`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0xE8`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x28`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0xBD`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x08`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x0F`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0xA0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x00`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x00`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x03`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x91`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x00`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x00`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x00`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x00`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x00`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x02`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`each`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`do`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{`aid`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# SELECT コマンドでアプリケーションを apdu`}</span>{`
    選択する `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0x00`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0xA4`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x04`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x00`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x10`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`*`}</span>{`aid`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x00`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
    card`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`transmit apdu`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`pack`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string-literal"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`"C*"`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# SELECT コマンドで EF 識別子\`00 04\`を選択する`}</span>{`
    apdu `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0x00`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0xA4`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x02`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x0C`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x02`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x00`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x04`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
    card`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`transmit apdu`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`pack`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string-literal"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`"C*"`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# READ BINARY コマンドでファイルを読み込む`}</span>{`
    data `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
    offset `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span>{`
    loop `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`do`}</span>{`
      apdu `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0x00`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
              `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0xB0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
              `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`offset `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x7FFF`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>>`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`8`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
              `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`offset `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x00FF`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
              `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x00`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
      response `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` card`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`transmit apdu`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`pack`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string-literal"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`"C*"`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
      response `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` Smartcard`}<span parentName="code" {...{
            "className": "token double-colon punctuation"
          }}>{`::`}</span>{`Iso`}<span parentName="code" {...{
            "className": "token double-colon punctuation"
          }}>{`::`}</span>{`IsoCardMixin`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`deserialize_response response`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`unpack`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string-literal"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`"C*"`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
      data`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`concat response`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token symbol"
          }}>{`:data`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
      `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`break`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` response`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token symbol"
          }}>{`:data`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`all`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`?`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{`e`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` e `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`==`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0xFF`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
      `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`break`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`unless`}</span>{` response`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token symbol"
          }}>{`:status`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`==`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x9000`}</span>{`
      offset `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+=`}</span>{` response`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token symbol"
          }}>{`:data`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`size
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span>{`
    pp decode_asn1 data`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` data
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`ensure`}</span>{`
  context`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`release
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span></code></pre></div>
    <p>{`prog03.rb を実行して実際に得られたデータ`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "shell"
    }}><pre parentName="div" {...{
        "className": "language-shell"
      }}><code parentName="pre" {...{
          "className": "language-shell"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`
 `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# 中略`}</span>{`
 `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`#<OpenSSL::ASN1::Sequence:0x00007ffdf99aaf70`}</span>{`
  @indefinite_length`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`false,
  @tag`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`16`}</span>{`,
  @tag_class`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`:UNIVERSAL,
  @tagging`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`nil,
  @value`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`
   `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token comment"
          }}>{`#<OpenSSL::ASN1::OctetString:0x00007ffdf99ab038`}</span>{`
     @indefinite_length`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`false,
     @tag`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`4`}</span>{`,
     @tag_class`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`:UNIVERSAL,
     @tagging`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`nil,
     @value`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"`}<span parentName="span" {...{
              "className": "token entity",
              "title": "\\x00"
            }}>{`\\x00`}</span><span parentName="span" {...{
              "className": "token entity",
              "title": "\\x16"
            }}>{`\\x16`}</span>{`"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`,
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`#<OpenSSL::ASN1::Integer:0x00007ffdf99aafe8`}</span>{`
     @indefinite_length`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`false,
     @tag`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span>{`,
     @tag_class`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`:UNIVERSAL,
     @tagging`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`nil,
     @value`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token comment"
          }}>{`#<OpenSSL::BN 0>>,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`#<OpenSSL::ASN1::ASN1Data:0x00007ffdf99aaf98`}</span>{`
     @indefinite_length`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`false,
     @tag`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span>{`,
     @tag_class`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`:CONTEXT_SPECIFIC,
     @value`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"`}<span parentName="span" {...{
              "className": "token entity",
              "title": "\\x05"
            }}>{`\\x05`}</span><span parentName="span" {...{
              "className": "token entity",
              "title": "\\x17"
            }}>{`\\x17`}</span>{`"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
 `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# 中略`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`
 `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# 中略`}</span>{`
 `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`#<OpenSSL::ASN1::Sequence:0x00007ffdfa072308`}</span>{`
  @indefinite_length`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`false,
  @tag`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`16`}</span>{`,
  @tag_class`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`:UNIVERSAL,
  @tagging`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`nil,
  @value`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`
   `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token comment"
          }}>{`#<OpenSSL::ASN1::OctetString:0x00007ffdfa072448`}</span>{`
     @indefinite_length`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`false,
     @tag`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`4`}</span>{`,
     @tag_class`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`:UNIVERSAL,
     @tagging`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`nil,
     @value`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"`}<span parentName="span" {...{
              "className": "token entity",
              "title": "\\x00"
            }}>{`\\x00`}</span><span parentName="span" {...{
              "className": "token entity",
              "title": "\\x16"
            }}>{`\\x16`}</span>{`"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`,
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`#<OpenSSL::ASN1::Integer:0x00007ffdfa0723d0`}</span>{`
     @indefinite_length`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`false,
     @tag`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span>{`,
     @tag_class`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`:UNIVERSAL,
     @tagging`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`nil,
     @value`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token comment"
          }}>{`#<OpenSSL::BN 0>>,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`#<OpenSSL::ASN1::ASN1Data:0x00007ffdfa072380`}</span>{`
     @indefinite_length`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`false,
     @tag`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span>{`,
     @tag_class`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`:CONTEXT_SPECIFIC,
     @value`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"`}<span parentName="span" {...{
              "className": "token entity",
              "title": "\\x05"
            }}>{`\\x05`}</span>{`%"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
 `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# 中略`}</span></code></pre></div>
    <p>{`これで公開鍵証明書ファイルの EF 識別子が`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`00 16`}</code>{`であることが判明しました。`}</p>
    <h2>{`公開鍵証明書を読み込む`}</h2>
    <p><em parentName="p">{`prog04.rb`}</em></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`# prog04.rb`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`require`}</span>{` `}<span parentName="code" {...{
            "className": "token string-literal"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`"smartcard"`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`require`}</span>{` `}<span parentName="code" {...{
            "className": "token string-literal"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`"openssl"`}</span></span>{`

context `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` Smartcard`}<span parentName="code" {...{
            "className": "token double-colon punctuation"
          }}>{`::`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`PCSC`}</span><span parentName="code" {...{
            "className": "token double-colon punctuation"
          }}>{`::`}</span><span parentName="code" {...{
            "className": "token class-name"
          }}>{`Context`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`begin`}</span>{`
  card `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` context`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`card context`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`readers`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`first

  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0xE8`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x28`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0xBD`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x08`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x0F`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0xA0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x00`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x00`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x03`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x91`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x00`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x00`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x00`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x00`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x00`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x01`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0xE8`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x28`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0xBD`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x08`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x0F`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0xA0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x00`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x00`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x03`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x91`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x00`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x00`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x00`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x00`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x00`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x02`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`each`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`do`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{`aid`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# SELECT コマンドでアプリケーションを選択する`}</span>{`
    apdu `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0x00`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0xA4`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x04`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x00`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x10`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`*`}</span>{`aid`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x00`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
    card`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`transmit apdu`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`pack`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string-literal"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`"C*"`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# SELECT コマンドで EF 識別子\`00 16\`を選択する`}</span>{`
    apdu `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0x00`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0xA4`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x02`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x0C`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x02`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x00`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x16`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
    card`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`transmit apdu`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`pack`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string-literal"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`"C*"`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# READ BINARY コマンドでファイルを読み込む`}</span>{`
    data `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
    offset `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span>{`
    loop `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`do`}</span>{`
      apdu `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0x00`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
              `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0xB0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
              `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`offset `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x7FFF`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>>`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`8`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
              `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`offset `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x00FF`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
              `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x00`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
      response `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` card`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`transmit apdu`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`pack`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string-literal"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`"C*"`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
      response `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` Smartcard`}<span parentName="code" {...{
            "className": "token double-colon punctuation"
          }}>{`::`}</span>{`Iso`}<span parentName="code" {...{
            "className": "token double-colon punctuation"
          }}>{`::`}</span>{`IsoCardMixin`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`deserialize_response response`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`unpack`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string-literal"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`"C*"`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
      data`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`concat response`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token symbol"
          }}>{`:data`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
      `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`break`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` response`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token symbol"
          }}>{`:data`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`all`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`?`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{`e`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` e `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`==`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0xFF`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
      `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`break`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`unless`}</span>{` response`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token symbol"
          }}>{`:status`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`==`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x9000`}</span>{`
      offset `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+=`}</span>{` response`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token symbol"
          }}>{`:data`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`size
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span>{`
    cert `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` OpenSSL`}<span parentName="code" {...{
            "className": "token double-colon punctuation"
          }}>{`::`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`X509`}</span><span parentName="code" {...{
            "className": "token double-colon punctuation"
          }}>{`::`}</span><span parentName="code" {...{
            "className": "token class-name"
          }}>{`Certificate`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`data`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`reverse_each`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`drop_while `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{`i`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` i `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`==`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0xFF`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`reverse`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`pack`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string-literal"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`"C*"`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    puts cert`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`to_text
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`ensure`}</span>{`
  context`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`release
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span></code></pre></div>
    <p>{`HPKI テストカードから DER 符号化された公開鍵証明書データが取得できるので、`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`OpenSSL::X509::Certificate.new`}</code>{`でインスタンス化できます。`}</p>
    <p>{`上記の prog04.rb を実行すると下記のような出力が得られます。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "shell"
    }}><pre parentName="div" {...{
        "className": "language-shell"
      }}><code parentName="pre" {...{
          "className": "language-shell"
        }}>{`Certificate:
    Data:
        Version: `}<span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`0x2`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
        Serial Number: `}<span parentName="code" {...{
            "className": "token number"
          }}>{`13023`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`0x32df`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    Signature Algorithm: sha256WithRSAEncryption
        Issuer: `}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`C`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`JP, `}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`O`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`Japan Medical Association, `}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`OU`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`Digital Certificate Center, `}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`CN`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`HPKI-01-HPKI_JV2-forNonRepudiation
        Validity
            Not Before: Aug `}<span parentName="code" {...{
            "className": "token number"
          }}>{`15`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`15`}</span>{`:00:00 `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2017`}</span>{` GMT
            Not After `}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span>{` Aug `}<span parentName="code" {...{
            "className": "token number"
          }}>{`15`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`14`}</span>{`:59:59 `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2018`}</span>{` GMT
        Subject: `}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`C`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`JP, `}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`CN`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`JMACombi20413/serialNumber`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`TESTC20413
        Subject Public Key Info:
            Public Key Algorithm: rsaEncryption
                Public-Key: `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`2048`}</span>{` bit`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
                Modulus:
                    00:94:dd:09:40:f4:58:f9:0f:ec:3a:ea:e3:47:33:
 `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# 中略`}</span>{`
                Exponent: `}<span parentName="code" {...{
            "className": "token number"
          }}>{`65537`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`0x10001`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
        X509v3 extensions:
            X509v3 Authority Key Identifier:
                keyid:44:E9:20:05:4D:6D:C4:B7:FA:4B:F0:1B:C6:EA:C8:D6:5B:16:22:F4
                DirName:/C`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`JP/O`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`Ministry of Health, Labour and Welfare/OU`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`Director-General `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`for`}</span>{` Policy Planning and Evaluation/OU`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`MHLW HPKI Root CA V2
                serial:02

            X509v3 Subject Key Identifier:
                9E:E5:71:59:1E:A7:FC:1E:4A:31:F8:7B:30:0B:E3:7F:05:3D:9A:40
            X509v3 Key Usage: critical
                Non Repudiation
            X509v3 CRL Distribution Points:

                Full Name:
                  URI:https://crl.pki.med.or.jp/repository/crl/crl-sign2.crl

            X509v3 Subject Directory Attributes:
                0402`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span>{`B`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span>{`$.`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"1 ...
*.............Medical Doctor
            X509v3 Certificate Policies: critical
                Policy: 1.2.392.100495.1.5.1.1.0.1
                  CPS: https://www.pki.med.or.jp/certpolicy/

    Signature Algorithm: sha256WithRSAEncryption
         84:ae:95:45:5e:e7:64:8b:0c:6e:20:5f:9f:1f:0d:5c:ae:4a:
 # 中略

Certificate:
    Data:
        Version: 3 (0x2)
        Serial Number: 12927 (0x327f)
    Signature Algorithm: sha256WithRSAEncryption
        Issuer: C=JP, O=Japan Medical Association, OU=Digital Certificate Center, CN=HPKI-01-HPKI_JV2-forAuthentication-forIndividual
        Validity
            Not Before: Aug 15 15:00:00 2017 GMT
            Not After : Aug 15 14:59:59 2018 GMT
        Subject: C=JP, CN=JMACombi20413/serialNumber=TESTC20413
        Subject Public Key Info:
            Public Key Algorithm: rsaEncryption
                Public-Key: (2048 bit)
                Modulus:
                    00:c6:f9:06:26:58:5e:11:b7:12:f2:8a:3e:97:0a:
 # 中略
                Exponent: 65537 (0x10001)
        X509v3 extensions:
            X509v3 Authority Key Identifier:
                keyid:62:12:93:82:DE:3C:D7:FF:A8:D3:63:01:D3:01:6A:AE:6C:3B:C0:D4
                DirName:/C=JP/O=Ministry of Health, Labour and Welfare/OU=Director-General for Policy Planning and Evaluation/OU=MHLW HPKI Root CA V2
                serial:03

            X509v3 Subject Key Identifier:
                45:2B:7B:B4:47:89:3D:6C:05:6D:82:4D:4C:C8:80:B8:B4:B0:89:81
            X509v3 Key Usage: critical
                Digital Signature
            X509v3 CRL Distribution Points:

                Full Name:
                  URI:https://crl.pki.med.or.jp/repository/crl/crl-auth2.crl

            X509v3 Subject Directory Attributes:
                0402..(..B..1(1&0$."`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span>{`.
*`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span>{`.Medical Doctor
            X509v3 Certificate Policies: critical
                Policy: `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1.2`}</span>{`.392.100495.1.5.1.2.0.1
                  CPS: https://www.pki.med.or.jp/certpolicy/

    Signature Algorithm: sha256WithRSAEncryption
 `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# 中略`}</span></code></pre></div>
    <p>{`それぞれのアプリケーションから正しく公開鍵証明書が取得できました。`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.jahis.jp/standard/detail/id=131"
      }}>{`電子認証ガイドライン`}</a>{`によると、電子認証に使用する証明書は Issuer の CN(Common Name)が`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`HPKI-01-*-forAuthentication-forIndividual`}</code>{`であることが定められているため、
使用した HPKI テストカードでは、電子認証に使用するアプリケーション識別子は`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`E8 28 BD 08 0F A0 00 00 03 91 00 00 00 00 00 02`}</code>{`であることが分かります。
また、電子署名に使用するアプリケーション識別子は`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`E8 28 BD 08 0F A0 00 00 03 91 00 00 00 00 00 01`}</code>{`であることが分かりました。`}</p>
    <h1>{`最後に`}</h1>
    <p>{`以上で`}<a parentName="p" {...{
        "href": "https://www.jahis.jp/standard/detail/id=609"
      }}>{`ガイドライン`}</a>{`の「A.2.2 JIS X 6320-15 に従った PKI カードアプリケーションの検索と利用」にある「PKI カードアプリケーションの検索」まで実装できました。`}</p>
    <p>{`今後、次のステップである暗号計算を実装していきたいと思います。`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      